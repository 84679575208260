
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ApiService from "@/core/services/ApiService";
import EntityInput from "@/components/EntityInput.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import { useRoute, useRouter } from "vue-router";
import router from "@/router";

export default defineComponent({
  name: "employee-form",
  props: {
    mode: {
      type: String,
      required: false,
      default: "new",
    },
  },
  components: {
    EntitySelect,
    EntityInput,
  },
  data() {
    return {
      errors: {},
      entityType: "users",
      entityData: {},
      statsData: {},
      verticalList: {},
      countryList: {},
      visibilityList: [
        {
          label: "Public",
          key: "public",
        },
        {
          label: "Private",
          key: "private",
        },
        {
          label: "VIP",
          key: "vip",
        },
      ],
    };
  },

  computed: {
    Title() {
      return this.mode == "new" ? "New User" : "User Edit";
    },
  },
  methods: {
    handleSave() {
      this.submitData().then(() => {
        ApiService.post(
          "users/" + this.$route.params.id.toString() + "/permissions",
          {
            affiliatePermissions: (this.user as any).affiliatePermissions,
            globalPermissions: (this.user as any).globalPermissions,
          }
        ).catch(this.catchErrors);
      });
    },
    getAffiliateName(index) {
      return this.affiliates.get(parseInt(index));
    },
    handleDeletePermission(affiliateId) {
      ApiService.delete(
        "users/" + this.$route.params.id.toString() + "/permissions/affiliate/",
        affiliateId
      ).then(() => {
        this.retrieveData(this.$route.params.id.toString());
      });
    },
    handleAddAffiliate() {
      Swal.fire({
        input: "select",
        inputOptions: this.filteredAffiliates,
        text: "Please select an affiliate to grant access",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
        preConfirm: (value) => {
          ApiService.post(
            "users/" +
              this.$route.params.id.toString() +
              "/permissions/affiliate",
            {
              affiliateId: value,
            }
          )
            .then(() => {
              this.retrieveData(this.$route.params.id.toString());
            })
            .catch(this.catchErrors);
        },
      });
    },
    catchErrors(error) {
      Swal.fire({
        text: error.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      this.errors = error.response.data.errors;
    },
    onSaveSuccess(response) {
      router.push({
        name: "user-list",
      });
    },
    retrieveData(id) {
      this.loading = true;
      ApiService.get(this.entityType, id)
        .then((result) => {
          this.loading = false;
          this.entityData = result.data.data;
          this.user = result.data.data;
          (this.entityData as any).moderator = result.data.data.moderator == 1;
        })
        .catch((err) => {
          this.loading = false;
          this.catchErrors(err);
        });
    },
    submitData() {
      console.log(this.entityData);
      if (this.mode == "edit") {
        return ApiService.update(
          this.entityType,
          this.$route.params.id.toString(),
          this.entityData
        )
          .then(this.onSaveSuccess)
          .catch(this.catchErrors);
      } else {
        return ApiService.post(this.entityType, this.entityData)
          .then(this.onSaveSuccess)
          .catch(this.catchErrors);
      }
    },
  },
  mounted() {
    this.loading = true;

    ApiService.query("affiliates", { perPage: 9999 })
      .then((result) => {
        this.loading = false;
        this.affiliateList = result.data.data;
      })
      .catch((error) => {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Cerrar",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      });
    ApiService.query("permissions", { perPage: 9999 })
      .then((result) => {
        this.loading = false;
        this.permissionList = result.data;
      })
      .catch((error) => {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Cerrar",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      });

    if (this.$route.params.id) {
      this.retrieveData(this.$route.params.id.toString());
    }

    ApiService.query("verticals", {
      params: { perPage: 9999, filters: {} },
    }).then((response) => {
      this.verticalList = response.data.data;
    });

    ApiService.query("countries", {
      params: { perPage: 9999 },
    }).then((response) => {
      this.countryList = response.data.data;
    });
  },
  created() {
    if (this.$route.params.id) {
      this.retrieveData(this.$route.params.id);
    }

    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        if (
          toParams.id !== "" &&
          toParams.id !== "new" &&
          toParams.id !== undefined
        ) {
          this.retrieveData(toParams.id);
        }
      }
    );
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("User Edit", ["Users"]);
    });

    let loading = ref(false);
    const affiliateList = ref([]);
    const permissionList = ref([]);
    let user = ref({});
    const affiliates = computed(() => {
      return new Map(
        affiliateList.value.map((item) => {
          return [(item as any).id, (item as any).label];
        })
      );
    });

    const filteredAffiliates = computed(() => {
      return new Map(
        affiliateList.value
          .filter((item) => {
            return (
              (user.value as any).affiliatePermissions[(item as any).id] ==
              undefined
            );
          })
          .map((item) => {
            return [(item as any).id, (item as any).label];
          })
      );
    });

    const affiliate = computed((index) => {
      return affiliates.value.get(index);
    });

    onMounted(() => {
      setCurrentPageTitle("User Details");
    });

    return {
      user,
      affiliateList,
      permissionList,
      filteredAffiliates,
      loading,
      affiliates,
      affiliate,
    };
  },
});
